<template>
  <div class="sales-parameter">
    <PageTitle
      title="基本設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />

    <section class="section-block">
      <SectionTitle title="首頁/頁尾設定" @edit="pageSettingDialog = true" />

      <BaseElForm label-position="left" label-width="200px">
        <BaseElFormItem label="logo設定">
          <!-- <img
            width="140px"
            style="border-radius: 4px;"
            :src="imgSource(config.Logo)"
            alt=""
          /> -->
          <UploadButton
            :img="formData.logo"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem label="Facebook連結">
          <div class="secret-text collapse">
            <span>{{ socialLinkList.facebook || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="Instagram連結">
          <div class="secret-text collapse">
            <span>{{ socialLinkList.instagram || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="YouTube連結">
          <div class="secret-text collapse">
            <span>{{ socialLinkList.youtube || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="Line ID">
          <div class="secret-text collapse">
            <span>{{ socialLinkList.line || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="Line 懸浮按鈕">
          <div class="secret-text collapse">
            <span>{{ config.floatingButton? config.floatingButton .enable? '開啟' : '關閉' : '關閉' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="版權所屬">
          <div class="secret-text collapse">
            <span>{{ socialLinkList.copyRight || '尚未設定' }}</span>
          </div>
        </BaseElFormItem>

        <!-- 聯絡我們 -->
        <BaseElFormItem label="營業時間">
          <span class="secret-text">{{ findData('shopOpenTime') || '尚未設定' }}</span>
        </BaseElFormItem>
        <BaseElFormItem label="聯絡電話">
          <span class="secret-text">{{ findData('shopPhone') || '尚未設定' }}</span>
        </BaseElFormItem>
        <BaseElFormItem label="聯絡地址">
          <span class="secret-text">{{ findData('shopAddress') || '尚未設定' }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <!-- <MemberPointConfigBlock
      v-if="checkAction('admin.shopPoint.page')"
      v-loading="loading"
      :configData="memberPointConfig"
      source="EC_ORDER"
      rewardType="POINT"
      @refresh="refresh"
    /> -->

    <image-cropper
      v-if="uploadDialog"
      :image="tempImage"
      :ratio="[20, 4]"
      :preview="false"
      notice="*長寬比5:1 ; 建議尺寸150x30px"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />

    <!-- 頁面設定 Dialog -->
    <el-dialog
      :visible.sync="pageSettingDialog"
      :close-on-click-modal="false"
      title="編輯首頁/頁尾設定"
      @close="resetForm"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="Facebook設定" prop="social.facebook">
          <BaseElInput v-model="formData.social.facebook" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="Instagram連結" prop="social.instagram">
          <BaseElInput v-model="formData.social.instagram" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="YouTuBe連結" prop="social.youtube">
          <BaseElInput v-model="formData.social.youtube" placeholder="請輸入" />
        </BaseElFormItem>
        <BaseElFormItem label="Line ID" prop="social.line">
          <BaseElInput
            v-model="formData.social.line"
            placeholder="請輸入"
          />
        </BaseElFormItem>
        <BaseElFormItem label="版權所屬" prop="social.copyRight">
          <BaseElInput
            v-model="formData.social.copyRight"
            placeholder="請輸入公司/組織名稱"
          />
        </BaseElFormItem>

        <BaseElFormItem label="Line 懸浮按鈕">
          <BaseElSwitch
            v-model="formData.floatButton"
            class="switch-input"
            active-text="開啟"
            inactive-text="關閉"
          />
        </BaseElFormItem>

        <!-- 聯絡我們 -->
        <BaseElFormItem label="營業時間">
          <BaseElInput v-model="formData.contactUs.shopOpenTime" show-word-limit />
        </BaseElFormItem>
        <BaseElFormItem label="聯絡電話" prop="shopPhone">
          <BaseElInput v-model="formData.contactUs.shopPhone" show-word-limit />
        </BaseElFormItem>
        <BaseElFormItem label="聯絡地址">
          <BaseElInput v-model="formData.contactUs.shopAddress" show-word-limit />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="pageSettingDialog = false">取消</BaseElButton>
        <BaseElButton type="primary" @click="updateSocial">儲存</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
// import InvoiceDialog from '@/views/Ecommerce/Dialog/InvoiceDialog.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { imgSrc } from '@/utils/helper'
// import * as cssvars from '@/styles/ohbot/_variables.scss'
import { noEmptyRules, urlRules, domainRules, emailRules } from '@/validation'
import formUtils from '@/utils/form'
import { GetConfig, UpdateEcommerceConfig } from '@/api/ecommerce/paramter'
import { GetFooterSetting, UpdateFooterSetting } from '@/api/ecommerce/footerSetting'
import { keys, map, filter, forEach, find } from 'lodash'
import { usePermissions } from '@/use/permissions'
// import MemberPointConfigBlock from '@/views/Parameters/components/MemberPointConfigBlock.vue'

export default {
  name: 'EcommerceParameter',
  components: {
    UploadButton, ImageCropper, SectionTitle, PageTitle,
    // MemberPointConfigBlock
  },
  setup (props) {
    const { checkAction } = usePermissions()
    return { checkAction }
  },
  data: () => ({
    loading: false,
    config: {},
    memberPointConfig: {},
    footerConfigData: null,
    tempImage: null,

    uploadDialog: false,
    pageSettingDialog: false,
    otherSettingDialog: false,
    linkSettingDialog: false,
    invoiceSettingDialog: false,

    avatarChanged: false,
    imgSize: 90,
    logoChange: false,

    formData: {
      logo: null,
      domain: '',
      customerLink: '',
      floatButton: false,
      notifyEmail: '',
      social: {
        facebook: '',
        instagram: '',
        youtube: '',
        copyRight: '',
        line: '',
      },
      contactUs: {
        shopOpenTime: '',
        shopPhone: '',
        shopAddress: '',
      },
    },
    formRules: {
      domain: [
        noEmptyRules(),
        domainRules(),
        { min: 6, message: '最少6個字元' },
      ],
      ecommerceLink: [noEmptyRules()],
      customerLink: [urlRules()],
      notifyEmail: [emailRules()],
      social: {
        facebook: [urlRules()],
        instagram: [urlRules()],
        youtube: [urlRules()],
        copyRight: '',
      },
    },
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
    socialLinkList () {
      const list = {}
      forEach(this.config.socialLinks, (item) => {
        if (item.type === 'line') list[item.type] = item.lineId
        else if (item.type === 'copyRight') list[item.type] = item.text
        else list[item.type] = item.link
      })
      return list
    },
    contactUsConfig () {
      return this.footerConfigData?.contactUs || {}
    },
    composeData () {
      const list = []
      forEach(this.formData.contactUs, (content, key) => {
        if (content) {
          list.push({
            type: 'text',
            text: content,
            name: key,
          })
        }
      })
      return list
    },
  },

  async mounted () {
    await this.refresh()
  },
  methods: {
    async refresh () {
      this.getConfig()
      this.getFooterSetting()
    },
    async resetForm () {
      await this.refresh()
      this.syncConfig()
      this.syncFooterConfig()
    },
    async getConfig () {
      const [res, error] = await GetConfig({
        shopId: this.shopId,
      })
      if (error) {
        this.$message.warning(error)
        this.config = {
          Logo: null,
          domain: '',
          enableOrderInvoice: false,
          enableOrderInvoiceB2B: false,
          enableOrderInvoiceLoveCode: false,
          enableOrderInvoiceCarrier: false,
          enableOrderInvoicePrint: false,
          enableOrderWallet: false,
          paymentDeadlineHour: false,
        }
        this.formData.logo = null

        await UpdateEcommerceConfig({
          shopId: this.shopId,
          enableOrderInvoice: false,
          enableOrderInvoiceB2B: false,
          enableOrderInvoiceLoveCode: false,
          enableOrderInvoiceCarrier: false,
          enableOrderInvoicePrint: false,
          enableOrderWallet: true,
          paymentDeadlineHour: 48,
          socialLinks: [],
        })
        return
      }
      this.config = res
      this.syncConfig(res)
    },

    async getFooterSetting () {
      const [res, err] = await GetFooterSetting({ shopId: this.shopId })
      if (err) return
      this.footerConfigData = res
      this.syncFooterConfig(res)
    },

    findData (key) {
      const exist = find(this.contactUsConfig, { name: key })
      if (exist) return exist.text
      return ''
    },
    // => 更新Logo
    async updateLogo () {
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        logo: this.formData.logo.id,
      })

      if (error) this.$message.error(error)
      console.log(res)
      this.pageSettingDialog = false
      await this.getConfig()
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新其他設定
    async updateOtherSetting (checkInput = false) {
      if (checkInput && !(await formUtils.checkForm(this.$refs.form))) return
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        adminNotifyEmail: this.formData.notifyEmail,
      })

      if (error) this.$message.error(error)
      console.log(res)

      this.otherSettingDialog = false
      if (!error) return this.$message.success('更新成功！')
    },

    // => 更新社群連結
    async updateSocial () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const socials = keys(this.formData.social)
      const linkList = map(socials, (key) => {
        if (key === 'line') {
          return {
            type: key,
            lineId: this.formData.social[key],
          }
        }
        if (key === 'copyRight') {
          return {
            type: key,
            text: this.formData.social[key],
          }
        }
        return {
          type: key,
          link: this.formData.social[key] || null,
        }
      })
      const [res, error] = await UpdateEcommerceConfig({
        shopId: this.shopId,
        socialLinks: filter(linkList, (item) => item.link || item.text || item.lineId),
        floatingButton: {
          type: 'socialLinkLine',
          enable: this.formData.floatButton,
        },
      })
      if (error) this.$message.error(error)
      await this.updateContactUs()
      this.pageSettingDialog = false
      // await this.refresh()
      if (!error) return this.$message.success('更新成功！')
    },

    async updateContactUs () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const [, err] = await UpdateFooterSetting({
        shopId: this.shopId,
        contactUs: this.composeData,
      })
      if (err) return this.$message.error(err)
      return true
    },

    syncConfig (res) {
      const config = res || this.config
      this.formData.logo = config.Logo
      this.formData.domain = config.domain
      for (const k in this.formData.social) {
        this.formData.social[k] = ''
      }
      forEach(config.socialLinks, (item) => {
        if (item.type === 'line') {
          this.formData.social[item.type] = JSON.parse(JSON.stringify(item.lineId))
        } else if (item.type === 'copyRight') this.formData.social.copyRight = item.text
        else this.formData.social[item.type] = item.link
      })
      this.formData.customerLink = config.customerServiceLink
      this.formData.floatButton = config.floatingButton?.enable
      this.formData.notifyEmail = config.adminNotifyEmail
    },
    syncFooterConfig (res) {
      const config = res || this.footerConfigData
      this.formData.contactUs.shopOpenTime = this.findData('shopOpenTime')
      this.formData.contactUs.shopPhone = this.findData('shopPhone')
      this.formData.contactUs.shopAddress = this.findData('shopAddress')
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    async getImage (data) {
      this.formData.logo = data
      this.logoChange = true
      this.uploadDialog = false

      await this.updateLogo()
    },

    loadImg (img) {
      this.tempImage = img
      this.uploadDialog = true
    },

  },
}
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
section {
  margin-top: 2rem;
  margin-bottom: 50px;
}

.title {
  font-size: 20px;
  margin-right: 10px;
}

.secret-text,
.note {
  @apply text-gray-80;
  // width: 100px;
  font-weight: 400;
  // @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.payments-list {
  width: 400px;
  word-wrap: break-word;
}

.copy-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  background: white;
  overflow: hidden;
  max-width: 440px;
  height: 44px;
  border-radius: 4px;

  a {
    font-weight: 400;
    @apply text-gray-80;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px 20px;
  }

  // .copy-btn {
  //   cursor: pointer;
  //   color: white;
  //   background: $primary-1;
  //   white-space: nowrap;
  //   padding: 8px 50px;
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     background-color: darken($color: $primary-1, $amount: 5);
  //   }
  // }
}
</style>
